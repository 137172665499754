import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { omit } from "lodash";
import { isElementInViewPort, removeFirstRowIfEmpty } from "../../common/fx";
import Box from "@mui/material/Box";
import { Button, LinearProgress, Tooltip, Typography } from "@mui/material";
import {
  GridRowModesModel,
  GridRowModes,
  DataGridPro,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
  useGridApiRef,
  GridRenderEditCellParams,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModel,
  GridValueFormatterParams,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-pro";
import {
  Add,
  AddCircleOutline,
  CopyAllOutlined,
  DeleteOutline,
  FormatTextdirectionLToR,
  PlaylistAdd,
  RemoveCircleOutline,
  ReportProblem
} from "@mui/icons-material";
import { AddForced } from "../../assets/icons/hhIcons";
import Notes from "../../modals/Notes";
import {
  HighlandCategoryOption,
  OptionTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import DropdownOptionsCell from "./DropdownOptionsCell";
import TextInputCell from "./TextInputCell";
import GridCustomFooter from "../../components/GridCustomFooter";
import { numberWithCommas } from "../../utils/formatMoney";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "../../styles/MainTheme";
import Loading from "../../components/Loading";
import MeasuredOptionsWizard from "../../modals/MeasuredOptionsWizard/MeasuredOptionsWizard";
import DeleteOptions from "../../modals/DeleteOptions";
import ConfirmDeleteAll from "../../modals/ConfirmDeleteAll";

interface Props {
  category: HighlandCategoryOption;
}

export default function EditGrid(props: Props) {
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const [gridRows, setGridRows] = useState<OptionTypes[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [firstId, setFirstId] = useState<String | null>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [hasForcedOptions, setHasForcedOptions] = useState<boolean>(false);
  const apiRefGrid = useGridApiRef();
  const addingRef = useRef<boolean>();
  addingRef.current = isAdding;

  useEffect(() => {
    const forcedOPtions = state.availableOptions.filter(
      (option: any) =>
        option.highlandCategory === props.category.key && option.forcedOption
    );
    setHasForcedOptions(forcedOPtions.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.availableOptions]);

  useEffect(() => {
    const newRows = buildRows();
    setGridRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder.pendingItems]);

  useEffect(() => {
    setGridColumns(buildColumns());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRows]);

  useEffect(() => {
    if (firstId === null) {
      const newFirstId = state.changeOrder.pendingItems.find(
        (item: OptionTypes) => item.highlandCategory.key === props.category.key
      );
      if (newFirstId) {
        if (newFirstId.id && !newFirstId.optionCode && newFirstId.isNew) {
          setRowModesModel({
            ...rowModesModel,
            [newFirstId.id]: { mode: GridRowModes.Edit },
          });
        }
        setFirstId(newFirstId.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridColumns]);

  

  const handleAddNewRow = (baseRowId?: GridRowId) => () => {
    const newPendingItems = [...state.changeOrder.pendingItems];
    if (addingRef.current === true) {
      return false;
    }
    setIsAdding(true);
    setTimeout(() => {
      setIsAdding(false);
    }, 250);

    let baseRowIx: number;
    let maxSortOrder = 0;
    if (baseRowId) {
      baseRowIx = newPendingItems.findIndex(
        (row: OptionTypes) => row.id === baseRowId
      );
      maxSortOrder = newPendingItems[baseRowIx].sortOrder;
    } else {
      baseRowIx = 0;
      newPendingItems.forEach((row: OptionTypes, rowIx: number) => {
        if (
          row.highlandCategory.key === props.category.key &&
          row.sortOrder > maxSortOrder
        ) {
          maxSortOrder = row.sortOrder;
          baseRowId = row.id;
          baseRowIx = rowIx;
        }
      });
    }
    const id = Math.random().toString(16).slice(2);
    const newSortOrder = maxSortOrder + 1;
    const possibleCategoryOptions = state.availableOptionCategories.filter(
      (optionCategory: any) =>
        optionCategory.highlandCategory === props.category.key
    );
    let newCategory = "";
    if (possibleCategoryOptions.length === 1) {
      newCategory = possibleCategoryOptions[0].category;
    }
    if (props.category.key === "custom") {
      newCategory = "Manual Option";
    }
    const newRow = {
      id: id,
      category: newCategory,
      forcedOption: false,
      highlandCategory: props.category,
      manualPrice: false,
      option: newCategory,
      optionCode: null,
      description: props.category.key === "custom" ? "" : null,
      notes: "",
      quantity: 1,
      unitPrice: 0,
      isNew: true,
      totalPrice: 0,
      sortOrder: newSortOrder,
    };
    newPendingItems.splice(baseRowIx + 1, 0, newRow);
    newPendingItems.forEach((row: any) => {
      if (
        row.highlandCategory.key === props.category.key &&
        row.sortOrder >= newSortOrder
      ) {
        row.sortOrder = row.sortOrder + 1;
      }
    });
    dispatch({
      type: "ChangeOrderPending",
      payload: newPendingItems,
      source: "AddNewRow",
    });

    setTimeout(() => {
      setRowModesModel((oldModel: any) => {
        const newRowModesModel = {
          ...oldModel,
          [id]: { mode: GridRowModes.Edit },
        };
        if (baseRowId) {
          newRowModesModel[baseRowId] = { mode: GridRowModes.View };
        }
        return newRowModesModel;
      });
      setTimeout(() => {
        const nextElementType = props.category.key === "custom" ? 'description' : 'option';
        const nextElement =  document.getElementById(`${id}_${nextElementType}`);
        nextElement?.focus();
        if(!isElementInViewPort(nextElement)) {
          nextElement?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 200);
    }, 300);
  };

  const handleAddAll = () => {
    const newPendingItems = removeFirstRowIfEmpty([...state.changeOrder.pendingItems], props.category);
    const hcOptions = state.availableOptions
      .filter(
        (item: any) =>
          item.highlandCategory === props.category.key &&
          item.option !== "Manual Option" &&
          item.option !== "Formatting Separator"
      )
      .map((item: any, iIx: number) => {
        const id = Math.random().toString(16).slice(2);
        return {
          id,
          category: item.category,
          forcedOption: item.forcedOption,
          highlandCategory: props.category,
          manualPrice: item.manualPrice || false,
          option: item.category,
          optionCode: item.optionCode,
          description: item.description,
          notes: "",
          quantity: 1,
          unitPrice: item.salesPrice,
          isNew: true,
          salesPriceControlID: item.salesPriceControlID,
          totalPrice: item.salesPrice,
          sortOrder: iIx + 1,
        };
      });
    dispatch({
      type: "ChangeOrderPending",
      payload: newPendingItems.concat(hcOptions),
      source: "AddAll",
    });
  };

  const handleAddForced = () => {
    const newPendingItems = removeFirstRowIfEmpty([...state.changeOrder.pendingItems], props.category);
    const hcOptions = state.availableOptions
      .filter(
        (item: any) =>
          item.highlandCategory === props.category.key &&
          item.option !== "Manual Option" &&
          item.option !== "Formatting Separator" &&
          item.forcedOption
      )
      .map((item: any, iIx: number) => {
        console.log(item);
        const id = Math.random().toString(16).slice(2);
        console.log(item);
        return {
          id,
          category: item.category,
          forcedOption: true,
          highlandCategory: props.category,
          manualPrice: item.manualPrice || false,
          option: item.category,
          optionCode: item.optionCode,
          description: item.description,
          notes: "",
          quantity: 1,
          unitPrice: item.salesPrice,
          isNew: true,
          salesPriceControlID: item.salesPriceControlID,
          totalPrice: item.salesPrice,
          sortOrder: iIx + 1,
        };
      });
    dispatch({
      type: "ChangeOrderPending",
      payload: newPendingItems.concat(hcOptions),
      source: "AddForced",
    });
  };

  const handleCopyClick = (id: GridRowId) => () => {
    if (addingRef.current === true) {
      return false;
    }
    setIsAdding(true);
    setTimeout(() => {
      setIsAdding(false);
    }, 250);

    const newId = Math.random().toString(16).slice(2);
    const rowToCopy: OptionTypes = state.changeOrder.pendingItems.find(
      (obj: OptionTypes) => obj.id === id
    );
    const rowToCopyIx = state.changeOrder.pendingItems.findIndex(
      (row: OptionTypes) => row.id === id
    );
    const newPendingItems = [...state.changeOrder.pendingItems];
    const newRow = omit(
      {
        ...rowToCopy,
        id: newId,
        isNew: true,
        notes: "",
        sortOrder: rowToCopy.sortOrder + 1,
      },
      "notes"
    );
    newPendingItems.splice(rowToCopyIx + 1, 0, newRow);
    newPendingItems.forEach((row: any) => {
      if (
        row.highlandCategory.key === rowToCopy.highlandCategory.key &&
        row.sortOrder > rowToCopy.sortOrder
      ) {
        row.sortOrder = row.sortOrder + 1;
      }
    });

    dispatch({
      type: "ChangeOrderPending",
      payload: [...newPendingItems],
      source: "Clone Row",
    });
    setTimeout(() => {
      setRowModesModel({
        ...rowModesModel,
        [rowToCopy.id]: { mode: GridRowModes.View },
        [newId]: { mode: GridRowModes.Edit },
      });
      setTimeout(() => {
        const nextElement = document.getElementById(`${newId}_option`);
        nextElement?.focus();
        if(!isElementInViewPort(nextElement)) {
          nextElement?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 200);
    }, 200);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedValue = state.changeOrder.pendingItems.filter(
      (row: { id: GridRowId }) => row.id !== id
    );
    dispatch({
      type: "ChangeOrderPending",
      payload: updatedValue,
      source: "Delete",
    });
  };

  /*
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, ignoreModifications: true },
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };
  */

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.enterKeyDown) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      params.reason = GridRowEditStopReasons.escapeKeyDown;
    }
  };

  const handleRowModesModelChange = useCallback(
    (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    },
    []
  );

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    return oldRow; // for some weird reason, this hack works like this. Don't f*** mess with this.
  };

  const onRowEditCommit = (test: any) => {
    console.log(test);
  };

  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    // gets a new array from the highlandCategory grid:
    const updatedCategoryItems = state.changeOrder.pendingItems.filter(
      (item: OptionTypes) =>
        item.highlandCategory.key === params.row.highlandCategory.key
    );
    // separate the rest elements
    const remainingItems = state.changeOrder.pendingItems.filter(
      (item: OptionTypes) =>
        item.highlandCategory.key !== params.row.highlandCategory.key
    );
    // do the sorting magic:
    const movedItem = updatedCategoryItems.splice(params.oldIndex, 1)[0];
    updatedCategoryItems.splice(params.targetIndex, 0, movedItem);
    updatedCategoryItems.forEach((item: OptionTypes, index: number) => {
      item.sortOrder = index + 1;
    });
    // union both arrays again:
    const newPendingItems = remainingItems.concat(updatedCategoryItems);
    dispatch({
      type: "ChangeOrderPending",
      payload: newPendingItems,
      source: "Row Drag&Drop",
    });
  };

  /*const preFillOption = (highlandCategory:HighlandCategoryOption) => {

  }

  const preFillCode = (option:any) => {

  }*/

  function RenderCellHeader() {
    return (
      <Box className="grid-tools" >
        <Button
          color="primary"
          variant="outlined"
          onClick={handleAddNewRow()}
          title="Add Item"
        >
          <Add />
        </Button>
        {props.category.key === "Structural" && (
          <Button
            color="primary"
            variant="outlined"
            onClick={handleAddAll}
            title="Add All"
          >
            <PlaylistAdd />
          </Button>
        )}
        {props.category.key === "Structural" && gridRows.length >= 5 && 
          <ConfirmDeleteAll
            highlandCategory={props.category}
          />
        }
        {hasForcedOptions && (
          <Button
            color="primary"
            variant="outlined"
            onClick={handleAddForced}
            title="Add Forced options"
          >
            <AddForced />
          </Button>
        )}
        { props.category.key === "MeasuredOption" && (
          <MeasuredOptionsWizard />
        )}
        { state.changeOrder.address && state.changeOrder.address.jobID &&
          <DeleteOptions highlandCategory={props.category} />
        }
      </Box>
    );
  }

  const renderTextInputCell: GridColDef["renderCell"] = (params: any) => {
    return <TextInputCell {...params} />;
  };

  const renderDropdownInputCell: GridColDef["renderCell"] = (params: any) => {
    return <DropdownOptionsCell {...params} />;
  };

  const buildRows = () => {
    return state.changeOrder.pendingItems
      .filter((item: OptionTypes) => {
        return item.highlandCategory.key === props.category.key;
      })
      .map((obj: any) => ({
        ...obj,
        __reorder__: obj.sortOrder,
      }));
  };

  const buildColumns = () => {
    const categoryOptions = [
      ...state.availableOptionCategories
        .filter(
          (optionCategory: any) =>
            optionCategory.highlandCategory === props.category.key
        )
        .sort((a: { category: string }, b: { category: string }) => {
          if (a.category > b.category) {
            return 1;
          }
          if (a.category < b.category) {
            return -1;
          }
          return 0;
        }),
    ];
    const availableOptions = [
      ...state.availableOptions.filter(
        (option: any) =>
          option.highlandCategory === props.category.key &&
          option.option !== "Manual Option" && 
          option.option !== "Formatting Separator"
      ),
      /*.sort((a: { category: string }, b: { category: string }) => {
          if (a.category > b.category) {
            return 1;
          }
          if (a.category < b.category) {
            return -1;
          }
          return 0;
        }),*/
    ];

    return [
      {
        disableColumnMenu: true,
        editable: false,
        headerName: "",
        field: "rowFlag",
        flex: 1,
        maxWidth: 50,
        valueGetter: (params:any) => {
          if (!!params.row.forcedOption) return "F";
          if (!!params.row.manualPrice) return "M";
          if (!!params.row.cancelsOptionId) return "C";
          return "";
        },
        width: 32,
        renderCell: (params: any) => {

          if (!!params.value) {
            let color = "";
            let toolTip = "";
            switch(params.value) {
              case "C":
                color = "#e56d29";
                toolTip = "Delete Option"
                break;
              case "F":
                color = mainTheme.palette.primary.main;
                toolTip = "Forced Option";
                break;
              case "M":
                color = "#e56d29";
                toolTip = "Manual Price";
                break;
            }
            return (
              <ThemeProvider theme={mainTheme}>
                <span
                  style={{
                    color,
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: "18px",
                  }}
                  title={toolTip}
                >
                  {params.value === "C" ? <RemoveCircleOutline /> : params.value}
                </span>
              </ThemeProvider>
            )
          }
          if(params.row.category === "Formatting Separator") 
            return <FormatTextdirectionLToR sx={{ color: mainTheme.palette.primary.main }} />;
          return "";
        },
      },
      {
        flex: 6,
        field: "option",
        headerName: "Option",
        editable: true,
        type: "string",
        valueOptions: categoryOptions,
        renderEditCell: (params: GridRenderEditCellParams) => {
          if(params.row.cancelsOptionId)
            return params.row.option;
          return (
            renderDropdownInputCell({
              ...params,
              apiRefGrid,
              setRows: dispatch,
              setRowModesModel: setRowModesModel,
              Rows: state.changeOrder.pendingItems,
              RowModel: rowModesModel,
            } as any)
          );
        }
      },
      {
        field: "optionCode",
        flex: 4,
        headerName: "Option Code",
        editable: true,
        valueOptions: availableOptions,
        renderCell: (params: any) => {
          if(params.row.category === "Formatting Separator") 
            return <div>===========</div>;
          return params.value;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          if(params.row.category === "Formatting Separator")
            return <div>===========</div>;
          if(params.row.cancelsOptionId)
            return params.row.optionCode;
          return params.row.option !== "Manual Option"
            ? renderDropdownInputCell({
                ...params,
                apiRefGrid,
                setRows: dispatch,
                setRowModesModel: setRowModesModel,
                Rows: state.changeOrder.pendingItems,
                RowModel: rowModesModel,
              } as any)
            : renderTextInputCell({
                ...params,
                type: "text",
                maxLength: 11,
                placeholder: "Enter your manual code",
                setRows: dispatch,
                setRowModesModel: setRowModesModel,
                Rows: state.changeOrder.pendingItems,
                RowModel: rowModesModel,
              } as any);
        },
      },
      {
        editable: true,
        field: "description",
        flex: 8,
        headerName: "Description",
        valueOptions: availableOptions,
        type: "string",
        renderEditCell: (params: GridRenderEditCellParams) => {
          if(params.row.cancelsOptionId)
            return params.row.description;
          return params.row.option === "Manual Option" || params.row.category === "Formatting Separator"
            ? renderTextInputCell({
                ...params,
                maxLength: 500,
                placeholder: "Enter a description (500-char limit; use Notes for more).",
                Rows: state.changeOrder.pendingItems,
                RowModel: rowModesModel,
                setRows: dispatch,
                setRowModesModel: setRowModesModel,
                type: "text",
              } as any)
              :
              renderDropdownInputCell({
                ...params,
                apiRefGrid,
                setRows: dispatch,
                setRowModesModel: setRowModesModel,
                Rows: state.changeOrder.pendingItems,
                RowModel: rowModesModel,
              } as any);
        },
      },
      {
        disableColumnMenu: true,
        flex: 1,
        field: "notes",
        headerName: "Notes",
        renderCell: (params: any) => {
          if (params.row.category === "Formatting Separator") return "=====";
          return (
            <Notes
              setRows={dispatch}
              rows={state.changeOrder.pendingItems}
              note={params}
            />
          );
        },
      },
      {
        flex: 2,
        field: "quantity",
        headerAlign: "right",
        headerName: "QTY",
        align: "right",
        editable: true,
        renderCell: (params: any) =>
          params.row.category === "Formatting Separator" ? "" : params.value,
        renderEditCell: (params: GridRenderEditCellParams) => {
          if (params.row.category === "Formatting Separator") return "";
          if(params.row.cancelsOptionId)
            return params.row.quantity;
          return (
            renderTextInputCell({
              ...params,
              align: "right",
              max: 999,
              min: 1,
              placeholder: "1",
              type: "number",
              setRows: dispatch,
              setRowModesModel: setRowModesModel,
              Rows: state.changeOrder.pendingItems,
              RowModel: rowModesModel,
            } as any)
          )
        }
      },
      {
        flex: 3,
        field: "unitPrice",
        headerAlign: "right",
        headerName: "Unit Price",
        align: "right",
        editable: true,
        valueGetter: (params: any) => params.row.unitPrice,
        renderCell: (params: any) =>
          params.row.category === "Formatting Separator"
            ? ""
            : `$ ${numberWithCommas(params.value)}`,
        renderEditCell: (params: GridRenderEditCellParams) => {
          if (params.row.category === "Formatting Separator") return "";
          if(params.row.cancelsOptionId)
            return params.row.unitPrice;
          return (
            !params.row.cancelsOptionId && 
            (params.row.manualPrice === true ||
            params.row.option === "Manual Option") ? (
              renderTextInputCell({
                ...params,
                align: "right",
                type: "number",
                disabled:
                  params.row.manualPrice !== true &&
                  params.row.option !== "Manual Option",
                min: -10000000,
                max: 10000000,
                placeholder: "0",
                setRows: dispatch,
                setRowModesModel: setRowModesModel,
                Rows: state.changeOrder.pendingItems,
                RowModel: rowModesModel,
              } as any)
            ) : (
              <Typography align="right">{params.formattedValue}</Typography>
            )
          );
        }
      },
      {
        flex: 3,
        field: "totalPrice",
        headerAlign: "right",
        headerName: "Total Price",
        align: "right",
        renderCell: (params: any) => params.row.category === "Formatting Separator" ?
          "" : `$ ${numberWithCommas(params.value)}`,
        valueGetter: (params: any) =>
          `${(params.row.quantity * Number(params.row.unitPrice)).toFixed(2)}`
      },
      {
        flex: 5,
        minWidth: 180,
        field: "actions",
        type: "actions",
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        hideable: true,
        pinnable: false,
        editable: false,
        headerName: "Actions",
        cellClassName: "actions",
        getActions: ({ id, row }: any) => {
          const rowActions = [
            <Tooltip title="Add a new row">
              <GridActionsCellItem
                color="inherit"
                icon={<AddCircleOutline />}
                id={`${id}_addrow`}
                label="Add a new row"
                onClick={handleAddNewRow(id)}
                sx={{ color: "primary.main" }}
                tabIndex={0}
              />
            </Tooltip>
          ];
          if(!row.cancelsOptionId) {
            rowActions.push(
              <Tooltip title={"Copy"}>
                <GridActionsCellItem
                  className="textPrimary"
                  color="inherit"
                  icon={<CopyAllOutlined />}
                  id={`${id}_copy`}
                  label="copy"
                  onClick={handleCopyClick(id)}
                  sx={{ color: "primary.main" }}
                  tabIndex={0}
                />
              </Tooltip>
            );
          }
          rowActions.push(
            <Tooltip title={"Delete"}>
              <GridActionsCellItem
                color="inherit"
                icon={<DeleteOutline />}
                id={`${id}_delete`}
                label="Delete"
                onClick={handleDeleteClick(id)}
                sx={{ color: "primary.main" }}
                tabIndex={0}
              />
            </Tooltip>
          );
          if (
            !row.category ||
            row.category === "" ||
            ((!row.description ||
              row.description === "") &&
             row.option !== "Formatting Separator")
          ) {
            rowActions.push(
              <Tooltip
                title={
                  "Please complete the option selection and entry to save the Change Order or delete this option if no longer needed."
                }
              >
                <GridActionsCellItem
                  color="inherit"
                  icon={<ReportProblem />}
                  id={`${id}_error`}
                  label="Row incomplete"
                  onClick={() => null}
                  sx={{ color: "#e56d29" }}
                />
              </Tooltip>
            );
          }
          return rowActions;
        },
        renderHeader: RenderCellHeader,
      },
    ];
  };

  function Footer() {
    return <GridCustomFooter highlandCategory={props.category} />;
  }

  return gridColumns && gridRows ? (
    <DataGridPro
      apiRef={apiRefGrid}
      columnHeaderHeight={46}
      columns={gridColumns}
      disableRowSelectionOnClick
      editMode={"row"}
      initialState={{
        pagination: { paginationModel: { pageSize: 100 } },
      }}
      onProcessRowUpdateError={(res) => console.log(res)}
      onRowEditCommit={onRowEditCommit}
      onRowEditStop={handleRowEditStop}
      onRowModesModelChange={handleRowModesModelChange}
      onRowOrderChange={handleRowOrderChange}
      pagination
      processRowUpdate={processRowUpdate}
      rowCount={gridRows.length}
      rowHeight={46}
      rowModesModel={rowModesModel}
      rowReordering={true}
      rows={gridRows}
      slotProps={{
        toolbar: { dispatch, setRowModesModel },
      }}
      slots={{
        loadingOverlay: LinearProgress,
        footer: Footer,
      }}
      sx={{
        "& .css-1iledgx-MuiFormControl-root": {
          marginBottom: "0px",
        },
      }}
    />
  ) : (
    <Loading />
  );
}
